import { mapGetters } from 'vuex'

export default {
    created: function () {

    },
    mounted() {
        setTimeout(this.updateIndexShowPost, 1000);
    },
    methods: {
        updateIndexShowPost() {
            const postOrderedIndex = this.getPostIndex;
            if (postOrderedIndex % 2 === 1) {
                const postTemp = this.getPostsOrdered[postOrderedIndex];
                if (postTemp) {
                // console.log('setIndexShowPost', postOrderedIndex + 1);
                    this.$store.dispatch('setIndexShowPost', postOrderedIndex + 2);
                }
            }

            // console.log('setIndexShowPost', postOrderedIndex + 1);
        },
        isAvailableButton(post, index) {
            return (index <= this.getIndexShowPost) &&
          (post.attributes.url !== 'votation' || this.getConfigKeyValue?.votation?.isVisible === true) &&
          (post.attributes.url !== 'winners' || this.getConfigKeyValue?.competition?.isOpen === false);
        }
    },
    computed: {
        ...mapGetters('cache', ['getPostsOrdered', 'getCountPostsVisibles']),
        ...mapGetters(['getIndexShowPost']),
        ...mapGetters('configGeneral', ['getConfigKeyValue']),
        getPostIndex() {
            let url = this.$route.params.url;
            if (!url) {
                url = this.$route.path?.replace("/app/pages/", "");
            }
            const getPostsOrdered = this.getPostsOrdered;
            const postOrderedIndex = getPostsOrdered.findIndex(post => post.attributes.url === url);
            return postOrderedIndex;
        },

        post() {
            const postOrderedIndex = this.getPostIndex;
            let postTemp = this.getPostsOrdered[postOrderedIndex];
            if (!postTemp) {
                postTemp = {
                    attributes: {
                        title: '',
                        title2: '',
                        body: '',
                        image: '',
                        image2: '',
                        'created-at': '',
                        'update-at': ''

                    }
                }
            }

            return postTemp
        },
        nextPost() {
            const postOrderedIndex = this.getPostIndex;

            if (postOrderedIndex >= 0 && postOrderedIndex < this.getPostsOrdered.length - 1) {
                const postNext = this.getPostsOrdered[postOrderedIndex + 1];
                if (this.isAvailableButton(postNext, postOrderedIndex + 1)) {
                    const url = postNext.attributes.body ? '/app/pages/post/' + postNext.attributes.url : '/app/pages/' + postNext.attributes.url;
                    return url;
                }
            }
        }
    }
}
