<template>
    <v-container>
        <div style="display: flex; flex-wrap: wrap; justify-content: flex-end">
            <!--            <span class="text-subtitle"> {{ $t('recognitions.searchUserToRecognize') }}</span>-->
            <c-btn
                v-if="users.length"
                mdi-icon="mdi-filter"
                :title="showFilterUsers ? $t('common.hideFilters') : $t('common.showFilters')"
                @click="showFilterUsers = !showFilterUsers"
            />
        </div>
        <!--      style="display: flex; flex-wrap: wrap; justify-content: flex-end"-->
        <div
            v-if="showFilterUsers"
        >
            <div class="menu-filter-recognition">
                <div>
                    <span class="text-h6">{{ $t('recognitions.filterUsers.country') }}</span>
                    <v-checkbox
                        v-model="selectAll.pais"
                        :label="selectAll.pais ? $t('common.selectAll') : $t('common.unselectAll')"
                        hide-details
                        class="mt-1 selectAllCHK"
                        @change="selectAllCountries"
                    />
                    <v-checkbox
                        v-for="key in getPaises"
                        :key="key"
                        v-model="filter.pais"
                        :label="key || 'vacio'"
                        :value="key"
                        hide-details
                        class="mt-1"
                        @change="checkSelectAllCountries"
                    />
                </div>

                <div>
                    <span class="text-h6">{{ $t('recognitions.filterUsers.center') }}</span>
                    <v-checkbox
                        v-model="selectAll.centro"
                        :label="selectAll.centro ? $t('common.selectAll') : $t('common.unselectAll')"
                        hide-details
                        class="mt-1 selectAllCHK"
                        @change="selectAllCentros"
                    />
                    <v-checkbox
                        v-for="key in getCentrosBySelectedPaises"
                        :key="key"
                        v-model="filter.centro"
                        :label="key || 'vacio'"
                        :value="key"
                        hide-details
                        class="mt-1"
                        @change="checkSelectAllCentros"
                    />
                </div>

                <div>
                    <span class="text-h6">{{ $t('recognitions.filterUsers.society') }}</span>
                    <v-checkbox
                        v-model="selectAll.sociedad"
                        :label="selectAll.sociedad ? $t('common.selectAll') : $t('common.unselectAll')"
                        hide-details
                        class="mt-1 selectAllCHK"
                        @change="selectAllSociedades"
                    />
                    <v-checkbox
                        v-for="key in getSociedadesByCentrosSelected"
                        :key="key"
                        v-model="filter.sociedad"
                        :label="key || 'vacio'"
                        :value="key"
                        hide-details
                        class="mt-1"
                        @change="checkSelectAllSociedades"
                    />
                </div>

                <div>
                    <span class="text-h6">{{ $t('recognitions.filterUsers.ou') }}</span>
                    <v-checkbox
                        v-model="selectAll.uo1"
                        :label="selectAll.uo1 ? $t('common.selectAll') : $t('common.unselectAll')"
                        hide-details
                        class="mt-1 selectAllCHK"
                        @change="selectAllUO1"
                    />
                    <v-checkbox
                        v-for="key in getUO1BySociedadesSelected"
                        :key="key"
                        v-model="filter.uo1"
                        :label="key || 'vacio'"
                        :value="key"
                        hide-details
                        class="mt-1"
                        @change="checkSelectAllUO1"
                    />
                </div>

                <slot />
            </div>
        </div>
    </v-container>
</template>

<script>

export default {
    name: "SelectFilters",
    components: {
        CBtn: () => import('../../components/base/c-btn.vue')
    },
    props: {
        usersLoaded: {
            type: Array,
            require: false,
            default() {
                return [];
            }
        }
    },
    data: function () {
        return {
            users: [],
            filter: {
                centro: [],
                sociedad: [],
                pais: [],
                uo1: []
            },
            filterMap: {},
            showFilterUsers: false,
            selectAll: {
                centro: true,
                sociedad: true,
                pais: true,
                uo1: true
            },
            sumLengthFilters: -1 // this variable store the filters length sum for know if the filtered are used
        }
    },
    computed: {
        getPaises() {
            return Object.keys(this.filterMap).filter(pais => pais).sort();
        },
        getCentrosBySelectedPaises() {
            return this.getCentrosBySelectedPaisesT();
        },
        getSociedadesByCentrosSelected() {
            return this.getSociedadesByCentrosSelectedT();
        },
        getUO1BySociedadesSelected() {
            return this.getUO1BySociedadesSelectedT();
        }
    },
    watch: {
        filter: {
            handler(value) {
                const sumLengthFiltersTemp = value.pais.length + value.centro.length + value.sociedad.length + value.uo1.length;
                if (sumLengthFiltersTemp !== this.sumLengthFilters) { // are using the filters
                    this.$emit('update:filter', value);
                } else {
                    this.$emit('update:filter', null);
                }
            },
            deep: true
        }
    },
    async mounted() {
        if (this.usersLoaded.length) this.users = this.usersLoaded
        else this.users = await this.$store.dispatch('cacheRecognitios/loadUsers');

        this.users.forEach(user => {
            const pais = (user.attributes.pais ?? '').trim();
            const centro = user.attributes.centro ?? '';
            const sociedad = user.attributes.sociedad ?? '';
            const uo1 = user.attributes.uo1 ?? '';

            if (!this.filterMap[pais]) {
                this.filterMap[pais] = {};
            }
            if (!this.filterMap[pais][centro]) {
                this.filterMap[pais][centro] = {};
            }
            if (!this.filterMap[pais][centro][sociedad]) {
                this.filterMap[pais][centro][sociedad] = {};
            }
            if (!this.filterMap[pais][centro][sociedad][uo1]) {
                this.filterMap[pais][centro][sociedad][uo1] = {};
            }

            this.filterMap[pais][centro][sociedad][uo1] = true;
        });

        // console.log("this.filterMap", this.filterMap)

        // selecting all the elements
        this.filter.pais = this.getPaises;

        this.filter.centro = this.getCentrosBySelectedPaisesT();

        this.filter.sociedad = this.getSociedadesByCentrosSelectedT();

        this.filter.uo1 = this.getUO1BySociedadesSelectedT();

        this.sumLengthFilters = this.filter.pais.length + this.filter.centro.length + this.filter.sociedad.length + this.filter.uo1.length;
        /* console.log('person ', this.person);
        const personNew = this.person;
        this.$emit('update:person', "");

        this.$emit('update:person', personNew); */
    },
    methods: {
        checkSelectAllCountries(options) {
            this.selectAll.pais = options.length === this.getPaises.length;
        },
        checkSelectAllCentros(options) {
            this.selectAll.centro = options.length === this.getCentrosBySelectedPaises.length;
        },
        checkSelectAllSociedades(options) {
            // this is because when a centro is unselected the value-sociedades-array-selected dont loose the value and dont exist
            const someReallyDontSelected = this.getSociedadesByCentrosSelected.some(sociedad => !options.includes(sociedad));
            this.selectAll.sociedad = !someReallyDontSelected;
        },
        checkSelectAllUO1(options) {
            const someReallyDontSelected = this.getUO1BySociedadesSelected.some(uo1 => !options.includes(uo1));
            this.selectAll.uo1 = !someReallyDontSelected;
        },
        selectAllCountries(option) {
            this.filter.pais = option ? this.getPaises : [];

            // console.log("this.person", this.person)
            // this.$emit('update:person', "10000001");
        },
        selectAllCentros(option) {
            this.filter.centro = option ? this.getCentrosBySelectedPaises : [];
        },
        selectAllSociedades(option) {
            this.filter.sociedad = option ? this.getSociedadesByCentrosSelected : [];
        },
        selectAllUO1(option) {
            this.filter.uo1 = option ? this.getUO1BySociedadesSelected : [];
        },
        getCentrosBySelectedPaisesT() {
            const paises = this.filter.pais;
            const centros = {};

            paises.forEach(cPais => {
                Object.keys(this.filterMap[cPais]).forEach(cCentro => {
                    centros[cCentro] = true;
                });
            });

            return Object.keys(centros).sort();
        },
        getSociedadesByCentrosSelectedT() {
            const paises = this.filter.pais;
            const centrosSelected = this.filter.centro;
            const sociedades = {};

            paises.forEach(cPais => {
                Object.keys(this.filterMap[cPais]).filter(cCentro => centrosSelected.includes(cCentro))
                    .forEach(cCentro => {
                        Object.keys(this.filterMap[cPais][cCentro]).forEach(cSociedad => {
                            sociedades[cSociedad] = true;
                        });
                    });
            });

            return Object.keys(sociedades).sort();
        },
        getUO1BySociedadesSelectedT() {
            const paises = this.filter.pais;
            const centrosSelected = this.filter.centro;
            const sociedadesSelected = this.filter.sociedad;
            const uo1s = {};

            paises.forEach(cPais => {
                Object.keys(this.filterMap[cPais]).filter(cCentro => centrosSelected.includes(cCentro))
                    .forEach(cCentro => {
                        Object.keys(this.filterMap[cPais][cCentro]).filter(cSociedad => sociedadesSelected.includes(cSociedad))
                            .forEach(cSociedad => {
                                Object.keys(this.filterMap[cPais][cCentro][cSociedad]).forEach(cUO1 => {
                                    uo1s[cUO1] = true;
                                });
                            });
                    });
            });

            return Object.keys(uo1s).sort();
        }
    }
}
</script>

<style lang="scss">
.menu-filter-recognition{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      row-gap: 20px;

  .text-h6{
    border-bottom: 1px solid #00b5e2;
    display: block;
    margin-left: 5px;
    margin-right: 5px;
  }

}
</style>
