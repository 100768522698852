<template>
    <div
        style="display: grid; grid-template-columns: 1fr; "
        class="ml-4"
    >
        <!--        <div
            v-if="(loading && !getVotedCoin)"
            style="grid-column: 2 / 4; display: flex; justify-content: center;"
        >
            <v-progress-circular
                :indeterminate="true"
                color="primary"
            />
        </div>

        <div
            v-if="getVotedCoin && !removing"
            style="grid-column: 2 / 4; display: flex; justify-content: center;"
        >
            <v-img
                :src="getMyLikes[getVotedCoin].image"
                contain
                style="'opacity: 1';  max-height: 80px; max-width: 80px;"
                class="cursos-pointer"
                @click="removeLike()"
            />
        </div>-->
        <!--      v-else-if="!loading"-->

        <div
            class="mx-6 my-0 py-0"
            style=" display: flex; justify-content: flex-end; flex-wrap: wrap; align-items: center"
        >
            <!--          :class="'imageCoin' +(getMyLikes[key].available ? ' cursos-pointer' : '')"
          src="@/assets/img/purposes/vote_bronze.png"
          :style="' ' + (!getMyLikes[key].available ? ' opacity: 0.2' : 'opacity: 1')"-->

            <!--            <span class="mr-2 text-small-grey"> {{ textToClickCoin }}</span>-->

            <v-progress-circular
                v-if="this.geLikesLoading"
                :size="60"
                :indeterminate="true"
                color="primary"
            />
            <img
                v-else-if="likeType === 1"
                :class="'imageCoin cursor-pointer'"
                src="@/assets/img/coins/selected.png"
                @click="removeLike(purposeId)"
            >
            <img
                v-else-if="likeType === 0"
                :class="'imageCoin cursor-pointer'"
                src="@/assets/img/coins/available.png"
                @click="doLike()"
            >
            <img
                v-else
                :class="'imageCoin'"
                src="@/assets/img/coins/locked.png"
            >
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'LikesComponnents',
    props: {
        purposeId: {
            type: Number,
            require: true
        },
        recognized_user_id: {
            type: Number,
            require: true
        },
        type_recognition: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            loading: false,
            removing: false
        }
    },
    mounted() {

    },
    computed: {
        ...mapGetters('statistics', ['getMyLikes', 'geLikesLoading']),
        likeType() { // -2 Spent coins, -1 Looked, 0 without Like, 1 Liked by current user
            if (this.getMyLikes) {
                const likeFound = this.getMyLikes.find(likeElem =>
                    +likeElem.recognized_user_id === +this.recognized_user_id &&
                    +likeElem.type_recognition === +this.type_recognition);

                if (likeFound) {
                    if (+likeFound.likeId === +this.purposeId) return 1;
                    else return -1;
                }
            }

            if (this.getMyLikes?.length >= 4) return -2;

            return 0;
        },
        textToClickCoin() {
            switch (this.likeType) {
            case -2: return this.$t('likes.spentCoin');
            case -1: return this.$t('likes.lockedCoin');
            case 1: return this.$t('likes.removeCoin');
            default: return this.$t('likes.assignCoin');
            }
        }
        /* getVotedCoin() {
            if (this.purposeId) {
                return Object.keys(this.getMyLikes).find(key => this.getMyLikes[key].purposes_cloud_id === this.purposeId)
            }
            return null
        } */
    },
    methods: {

        doLike(coin) {
            this.$emit('doLike', 1)

            // this.$store.dispatch('snackbar/error', this.$t('purposes.errorUsedCoin'))
        },
        removeLike() {
            this.loading = false;
            this.removing = true;
            this.$emit('removeLike', parseInt(this.getVotedCoin));
        }
    }
}
</script>

<style scoped>
.imageCoin {
  max-width: 60px;
  max-height: 60px;
  width: auto;
  height: auto;
}
</style>
