<template>
    <div>
        <span class="text-h6">{{ $t('likes.seeOnlyMyCoinsTitle') }}</span>
        <v-checkbox
            v-model="value"
            :label="$t('likes.seeOnlyMyCoins')"
            hide-details
            class="mt-1"
        />
    </div>
</template>

<script>

export default {
    name: "SelectMyCoins",
    data: function () {
        return {
            value: false
        }
    },
    watch: {
        value: {
            handler(val) {
                this.$emit('update:filter', val);
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
