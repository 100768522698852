<template>
    <div style="max-width: 280px">
        <span class="h6 grey--text">{{ $t('statistic.coins.availableCoins') }}</span>
        <div
            style="display: grid; grid-template-columns: repeat(4, 25%); min-height: 60px"
            class="ml-4"
        >
            <div
                v-for="n in 4"
                :key="n"
                class="mx-6 my-0 py-0"
                style=" display: flex; justify-content: center; flex-wrap: wrap; "
            >
                <!--          :class="'imageCoin' +(getMyLikes[key].available ? ' cursos-pointer' : '')"
          src="@/assets/img/purposes/vote_bronze.png"
          :style="' ' + (!getMyLikes[key].available ? ' opacity: 0.2' : 'opacity: 1')"-->
                <v-progress-circular
                    v-if="myAvailableCoins === -1"
                    :size="40"
                    :indeterminate="true"
                    color="primary"
                />

                <img
                    v-else-if="n <= myAvailableCoins"
                    :class="'imageCoin '"
                    src="@/assets/img/coins/selected.png"
                >
                <img
                    v-else
                    :class="'imageCoin '"
                    src="@/assets/img/coins/available.png"
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'MyAvailableCoinsComponnents',
    data() {
        return {

        }
    },
    mounted() {

    },
    computed: {
        ...mapGetters('statistics', ['getMyLikes', 'geLikesLoading']),
        myAvailableCoins() {
            if (this.geLikesLoading) return -1; // loading

            const count = this.getMyLikes?.length
            if (!isNaN(count)) {
                return count >= 4 ? 0 : 4 - count;
            }

            return -1; // loading
        }

    },
    methods: {

    }
}
</script>

<style scoped>
.imageCoin {
  max-width: 60px;
  max-height: 60px;
  width: auto;
  height: auto;
}
</style>
