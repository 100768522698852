export default [

    {
        name_es: 'Actuamos con integridad',
        name_en: 'We operate with integrity',
        id: 1
    },
    {
        name_es: 'Innovamos para el futuro',
        name_en: 'We innovate for the future',
        id: 2
    },
    {
        name_es: 'Avanzamos hacia la excelencia',
        name_en: 'We move towards excellence',
        id: 3
    },
    {
        name_es: 'Cuidamos a nuestras personas',
        name_en: 'We care for our people',
        id: 4
    }
]
