<template>
    <v-container
        fluid
        class="pt-0"
    >
        <div style="min-height: 3.15rem;">
            <selectFilters
                v-if="users.length"
                :users-loaded="users"
                @update:filter="filtersUsers = $event"
            >
                <selectRecognitionType @update:filter="filterTypes = $event" />

                <selectMyCoins @update:filter="showOnlyMyCoins = $event" />
            </selectFilters>
        </div>
        <!--      Add purpose-->
        <span
            class="h6 grey--text"
        >
            {{ $tc('votesRecognitions.showingNumber', dataFilter.length, {
                total: items.length})
            }}
        </span>
        <v-row>
            <v-col class="px-2 pt-0">
                <!--      Purposes List-->
                <!--      :search="search"-->
                <v-data-iterator
                    :items="dataFilter"
                    :items-per-page.sync="itemsPerPage"
                    :page.sync="page"
                    :sort-by="sort.att"
                    :sort-desc="sort.desc"
                    hide-default-footer
                    no-data-text=""

                    :custom-sort="customSort"
                >
                    <template v-slot:header>
                        <v-toolbar

                            dark
                            color="primary"
                            class="mb-1  rounded-lg"
                        >
                            <v-text-field
                                v-model="search"
                                clearable
                                flat
                                solo-inverted
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                                :label="$t('common.search')"
                            />

                            <template>
                                <!--                        <v-spacer />
                        <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            :items="keys"
                            prepend-inner-icon="mdi-magnify"
                            label="Sort by"
                        />-->
                                <v-spacer />
                                <!--                                <v-btn
                                    dark
                                    icon
                                    @click="dialogFilter = true"
                                >
                                    <v-icon>{{ filtersSelected.length ? 'mdi-filter' : 'mdi-filter-off' }}</v-icon>
                                </v-btn>-->

                                <!--                                <v-menu
                                    bottom
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dark
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>{{ sort.icon }}</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item-group
                                            v-model="sortBy"
                                            color="primary"
                                            mandatory
                                        >
                                            &lt;!&ndash;                                          @click="updateSort(item)"&ndash;&gt;
                                            <v-list-item
                                                v-for="(item, i) in menuSort"
                                                :key="item.value"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon v-text="item.icon" />
                                                </v-list-item-icon>
                                                <v-list-item-content>{{ item.text }}</v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>-->
                            </template>
                        </v-toolbar>

                        <!--                        <v-expansion-panels
                            class="mb-2 mt-3"
                            flat
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    expand-icon="mdi-menu-down"
                                    class="grey&#45;&#45;text"
                                >
                                    <strong>Recuerda como votar!!!</strong>
                                    <template v-slot:actions>
                                        <v-icon color="primary">
                                            $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <ul class="grey&#45;&#45;text">
                                        <li class="mb-2">
                                            Tienes 4 votos para otorgar 6, 4, 3 o 2 puntos a la misión que más te guste:
                                            <div
                                                style="display: grid;
                                                grid-template-columns: repeat(4, 25%);
                                                justify-items: center;"
                                            >
                                                <v-img
                                                    src="@/assets/img/purposes/vote_bronze.png"
                                                    contain
                                                    style="max-height: 65px; max-width: 65px;"
                                                />
                                                <v-img
                                                    src="@/assets/img/purposes/vote_silver.png"
                                                    contain
                                                    style="max-height: 65px; max-width: 65px;"
                                                />
                                                <v-img
                                                    src="@/assets/img/purposes/vote_gold.png"
                                                    contain
                                                    style="max-height: 65px; max-width: 65px;"
                                                />
                                                <v-img
                                                    src="@/assets/img/purposes/vote_platinum.png"
                                                    contain
                                                    style="max-height: 65px; max-width: 65px;"
                                                />
                                            </div>
                                        </li>

                                        <li class="mb-2">
                                            Para asignar un voto elige una misión que te guste y haz clic en el voto que desees otorgarle.
                                        </li>

                                        <li class="mb-2">
                                            Gastar todos tus votos le aporta 5 puntos a tu propia misión (Votar da puntos!)
                                        </li>

                                        <li>Puedes retirar los votos cuando quieras o cuando cambies de opinión para dárselos a otra misión (vuelve hacer clic sobre tu voto y este desaparecerá)</li>
                                    </ul>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>-->
                    </template>

                    <template v-slot:default="props">
                        <div class="pt-6" />
                        <div
                            v-for="item in props.items"
                            :key="item.key"
                            class="mb-4"
                        >
                            <voteRecognitionsSingle
                                :item="item"
                                @doLike="doLike(item, $event)"
                                @removeLike="removeLike(item, $event)"
                            />

                            <!--                                    <v-divider v-if="canShowCoins(item) || competitionEnded" />
                                    <v-row>
                                        <v-col
                                            class="pl-1 ml-1 mr-4"
                                        >
                                            <myActiveCoins
                                                v-if="competitionEnded === false && canShowCoins(item)"
                                                :purpose-id="item.id"
                                                @doLike="doLike(item, $event)"
                                                @removeLike="removeLike(item, $event)"
                                            />

                                            <div
                                                v-else-if="competitionEnded"
                                                style="display: flex; justify-content: center; align-items: center;"
                                            >
                                                <v-avatar
                                                    class="mr-2"
                                                    color="primary"
                                                    size="44"
                                                >
                                                    <span class="white&#45;&#45;text text-20">
                                                        {{ item.likes }}
                                                    </span>
                                                </v-avatar>

                                                {{ $t('global.points') }}
                                            </div>-->

                            <!--       Code for show likes and dislikes-->
                            <!--                                            <div class="mr-3">
                                                <v-icon
                                                    :color="myLikes[item.id] === 1 ? 'secondary' : ''"
                                                    @click="doLike(item, 1)"
                                                >
                                                    mdi-thumb-up
                                                </v-icon>

                                                {{ item.likes }}
                                            </div>

                                            <div>
                                                <v-icon
                                                    :color="myLikes[item.id] === -1 ? 'secondary' : ''"
                                                    @click="doLike(item, -1)"
                                                >
                                                    mdi-thumb-down
                                                </v-icon>

                                                {{ item.dislikes }}
                                            </div>-->
                            <!--                                        </v-col>
                                    </v-row>-->
                        </div>
                    </template>

                    <!--                    <template v-slot:no-data>
                        <div class="d-flex flex-column align-center text-gray-600 mt-6">
                            <span class="text-h5 text-center">
                                {{ $t('purposes.withoutPurposes') }}
                            </span>

                            <span class="text-subtitle-2">
                                {{ $t('purposes.createMission') }}
                            </span>
                        </div>
                    </template>-->
                    <template
                        v-if="items && items.length"
                        v-slot:footer
                    >
                        <v-row
                            class="mt-2 ml-2 mr-1"
                            align="center"
                            justify="center"
                        >
                            <span class="grey--text">{{ $t('common.itemsPerPage') }}</span>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        text
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{ itemsPerPage }}
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(number, index) in itemsPerPageArray"
                                        :key="index"
                                        @click="updateItemsPerPage(number)"
                                    >
                                        <v-list-item-title>{{ number }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <v-spacer />

                            <span
                                class="mr-4 grey--text"
                            >
                                {{ $t('common.page') + " " + page + " " + $t('common.of') + " " + numberOfPages }}
                            </span>
                            <div>
                                <v-btn
                                    fab
                                    dark
                                    color="primary"
                                    class="mr-1"
                                    @click="formerPage"
                                >
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    dark
                                    color="primary"
                                    class="ml-1 mr-2"
                                    @click="nextPage"
                                >
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </div>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>

        <myAvailableCoinsComponnents />

        <!--        <groupsTreeDialogSelect
            :items-allowed="groupsToSelect"
            :dialog="dialogFilter"
            :items-selected="filtersSelected"
            @update:itemsSelected="changefiltersSelected"
            @update:dialog="dialogFilter = $event"
        />-->

        <confirm-dialog
            ref="confirm"
        />
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
import ConfirmDialog from '@/components/common/ConfirmDialog'
/* import groupsTreeDialogSelect from '@/components/groups/groupsTreeDialogSelect' */
// import myPurposeCloud from '@/components/purpose/myPurposeCloud'

/* import purposeDate from '@/components/purpose/purposeDate'
import purposeText from '@/components/purpose/purposeText'
import myActiveCoins from '@/components/likes/myActiveCoins' */
import voteRecognitionsSingle from "@/components/votesRecognitions/voteRecognitionsSingle";

import selectFilters from "@/components/recognitions/selectFilters";
import selectRecognitionType from "@/components/recognitions/selectRecognitionType";
import myAvailableCoinsComponnents from "@/components/likes/myAvailableCoinsComponnents";
import selectMyCoins from "@/components/recognitions/selectMyCoins";

export default {
    name: 'VoteRecognitionsList',
    components: { ConfirmDialog, voteRecognitionsSingle, selectFilters, selectRecognitionType, selectMyCoins, /* groupsTreeDialogSelect, */ /* purposeDate, purposeText, */ myAvailableCoinsComponnents },
    /* components: { myPurposeCloud }, */
    data() {
        return {
            urlMain: '/purposes?include=recognized_user',
            items: [], // keys (userId + '-' + typeRecognition)
            recongnitionsMap: {},
            filtersSelected: [],
            itemSelected: null,
            dialogFilter: false,
            myLikes: [],
            itemsPerPageArray: [5, 10, 50],
            search: '',
            filter: {},
            page: 1,
            itemsPerPage: 5,
            menuSort: [
                { value: 0, text: this.$t('purposes.menuSort.dateUpdateDesc'), icon: 'mdi-sort-calendar-ascending' },
                { value: 1, text: this.$t('purposes.menuSort.dateUpdateAsc'), icon: 'mdi-sort-calendar-descending' },
                { value: 2, text: this.$t('purposes.menuSort.random'), icon: 'mdi-sort-variant-remove' }
                /* { value: 2, text: 'Me gusta Desc', icon: 'mdi-sort-numeric-ascending' },
                { value: 3, text: 'Me gusta Asc', icon: 'mdi-sort-numeric-descending' },
                { value: 4, text: 'No me gusta Desc', icon: 'mdi-sort-bool-ascending' },
                { value: 5, text: 'No me gusta Asc', icon: 'mdi-sort-bool-descending' } */
            ],
            sortBy: 2, // random,
            purposeCloud: null,
            usersMap: {},
            timeWait: 0,
            overlay: false,
            competitionEnded: null,
            filtersUsers: null,
            filterTypes: null,
            users: [], // users included
            showOnlyMyCoins: false
        }
    },
    computed: {
        sort() {
            const menuSelected = this.menuSort.find(e => e.value === this.sortBy);

            const desc = false;// menuSelected.text.endsWith('Desc');
            const icon = menuSelected.icon;
            let att = 'updated_at';
            if (this.sortBy > 1) {
                att = 'random';
                // att = this.sortBy > 3 ? 'dislikes' : 'likes';
            }
            return { att, desc, icon };
        },
        getItemsWithMySelection() {
            const items = this.items;
            const filter = this.showOnlyMyCoins;

            if (filter) {
                const myLikes = this.getMyLikes.map(likeObj => likeObj.likeId + '');

                return items.filter(item => {
                    return myLikes.includes(item.id);
                });
            } else {
                return items;
            }
        },
        getItemsWithFiltersTypesRecognition() {
            const items = this.getItemsWithMySelection;
            const filter = this.filterTypes;

            if (filter) {
                return items.filter(item => {
                    return filter.includes(item.attributes.type_recognition);
                });
            } else {
                return items;
            }
        },
        getItemsWithFiltersUsers() {
            const items = this.getItemsWithFiltersTypesRecognition;
            const filter = this.filtersUsers;
            if (filter) {
                return items.filter(child => {
                    // const userDataFilter = (child?.userData?.attributes?.name ?? "") + " " + (child?.userData?.attributes?.lastname ?? "");
                    const u = child?.userData; // child?.ownerData

                    return (u &&
                        filter.pais.includes(u.attributes.pais ?? '') &&
                        filter.centro.includes(u.attributes.centro ?? '') &&
                        filter.sociedad.includes(u.attributes.sociedad ?? '') &&
                        filter.uo1.includes(u.attributes.uo1 ?? ''));
                });
            } else {
                return items;
            }
        },
        dataFilter() {
            const search = this.search?.toUpperCase();

            let values = this.getItemsWithFiltersUsers
            if (search) {
                values = values.filter(child => {
                    const userDataFilter = (child?.userData?.attributes?.name ?? "") + " " + (child?.userData?.attributes?.lastname ?? "");
                    const att = child.attributes;
                    const bodyText = this.generalHelper.stripTags(att.body ?? '').toUpperCase();

                    return userDataFilter.toUpperCase().includes(search) ||
                        (bodyText.includes(search));
                });

                /* values = values.filter(child => (child.body && child.body.toUpperCase().includes(search.toUpperCase())) ||
                    (child.created_at && this.dateHelper.getDateTimeToFormatAndLocale(child.created_at).includes(search)) ||
                    (child.updated_at && this.dateHelper.getDateTimeToFormatAndLocale(child.updated_at).includes(search))
                ); */
            }

            /* if (this.enableGroupFilter && this.filtersSelected?.length) {
                values = values.filter(child => { // filter over the purposesCLoud
                    if (child.group_id) {
                        const groupId = child.group_id;
                        for (const groupSingleSelected of this.filtersSelected) { // loop over selected groups
                            if (groupSingleSelected == groupId ||
                                (this.groups.map[groupId].parent_id && this.groups.map[groupId].parent_id == groupSingleSelected)
                            ) return true;
                        }
                    }

                    return false;
                }
                );
            } */

            return values;
        },
        numberOfPages() {
            return Math.ceil(this.dataFilter.length / this.itemsPerPage)
        },
        havePurposesToVote() {
            const items = this.items;
            if (items.length === 0) return false;
            if (items.length === 1) {
                if (this.getUser.id == items[0].user_id) { return false; }
            }
            return true;
        },
        ...mapGetters('statistics', ['getMyLikes']),
        ...mapGetters('permissions', ['canDeletePurposesCloud', 'canUpdatePurposesCloud']),
        ...mapGetters(['getUser'])
        /* filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        } */
    },
    watch: {
        sortBy(val) {
            if (val === 2) { // random
                this.shuffleArray();
            }
        }
    },
    async created() {
        this.timeWait++;
        await this.fetchPurpose();

        this.$store.dispatch('statistics/loadMyLikes');

        this.timeWait--;
    },
    async mounted() {
        const name = this.$route.params.filterName;
        if (name) { this.search = name }
        // uncomment for see for default the missions of the user subgroup
        // this.filtersSelected = this.getMyGroups.map(e => String(e));

        // this.overlay = this.$store.getters['cache/canShowVideoPurposeCreate'];
        // await this.$store.dispatch('cache/setShowVideoPurposeCreate', false);
    },
    methods: {
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('purposes.confirmDeleteMision')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    const index = this.items.indexOf(item);
                    if (index > -1) {
                        this.items.splice(index, 1);
                    }
                    // this.fetchPurpose();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                /* if (error.response) {
              console.log(error.response.data.error) // "invalid_credentials"
              console.log(error.response.status)
              console.log(error.response.headers)
          } */
            }
            this.$store.dispatch('loading/end')
        },
        async fetchPurpose() {
            try {
                this.$store.dispatch('loading/start');

                /* const purposes = await axios
                    .get('/indexPurposesCLoud') */

                const purposes = await axios({
                    url: this.urlMain,
                    timeout: 50000 // Wait for 50 seconds
                });

                this.initialize(purposes.data)
            } catch (e) {
                // console.log('Error: ')
                // console.log(e)
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            } finally {
                this.$store.dispatch('loading/end');
            }
        },
        /* fetchUserData(item) {
            const id = item.user_id;

            if (!item.user) {
                this.$set(item, 'user', { id, username: "loading...", acronym: "", picture: '' });

                setTimeout(() => {
                    axios({
                        url: '/getUserNamePictureByUserId/' + id,
                        timeout: 30000 // Wait for 30 seconds
                    })
                        .then(userdata => {
                            item.user.username = userdata.data.user.username;
                            item.user.picture = !userdata.data.user.picture ? ''
                                : process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=users/images/' + userdata.data.user.picture;

                            let acronym = item.user.username.substring(0, 1).toUpperCase();
                            acronym += item.user.username.substring(1).split("").find(e => e.toUpperCase() === e) ?? "";
                            item.user.acronym = acronym;

                            /!* console.log(item.user)
                      console.log(' userdata.data') *!/
                        }).catch(e => {
                            /!* console.log('Error getUserNamePictureByUserId: ')
                            console.log(e) *!/
                            this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
                        }).finally(() => {
                            this.timeWait--;
                        });
                }, this.timeWait * 500);

                this.timeWait++;
            };

            return item.user.id;
        }, */
        async doLike(item, value) {
            try {
                // this code is for increase or decrease the likes/dislike
                /* if (this.myLikes && this.myLikes[item.id] !== value) {
                    // this is for add a like/dislike or change my vote
                    if (value > 0) {
                        item.likes += value;

                        if (this.myLikes[item.id]) {
                            item.dislikes -= value;
                        }
                    } else if (value < 0) {
                        item.dislikes += value;

                        if (this.myLikes[item.id]) {
                            item.likes -= value;
                        }
                    }
                } else if (this.myLikes && this.myLikes[item.id] === value) {
                    // this is for remove the current like/dislike
                    if (value > 0) {
                        item.likes -= value;
                    } else if (value < 0) {
                        item.dislikes -= value;
                    }

                    value = 0;
                }
                // console.log(item)

                this.myLikes[item.id] = value;
*/

                this.$store.dispatch('statistics/setLikeLoading', true);

                const votation = {
                    purposes_cloud_id: item.id
                    /* like: value,
                    groupId: 0 */
                };

                const doLike = await axios
                    .post('doLike', votation);

                if (doLike.status === 200) {
                    this.$store.dispatch('statistics/addLike', { likeId: +item.id, like: 1, recognized_user_id: item.attributes.recognized_user_id, type_recognition: item.attributes.type_recognition });
                }
            } catch (error) {
                // console.log('error.response', error)
                // console.log('Error myLikes: ')
                // console.log(e)
                if (!error || !error.response) {
                    this.$store.dispatch('snackbar/error', this.$t('common.error.network'))
                } else if (error.response.status !== 423) { // Error 423 is that the competition close and is managed globally
                    if (error.response.status === 403) {
                        this.$store.dispatch('snackbar/error', this.$t('groups.groupsProblemNeedtoLoging'));
                    } else { this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData')) }
                }
            }

            this.$store.dispatch('statistics/setLikeLoading', false);
        },
        async removeLike(item, value) {
            this.$store.dispatch('statistics/setLikeLoading', true);

            try {
                const votation = {
                    purposes_cloud_id: item.id/*,
                    like: value */
                };

                const removeLike = await axios
                    .post('removeLike', votation);

                if (removeLike.status === 200) {
                    this.$store.dispatch('statistics/removeLike', { likeId: +item.id, like: 1 });
                }
            } catch (error) {
                if (!error || !error.response) {
                    this.$store.dispatch('snackbar/error', this.$t('common.error.network'))
                } else if (error.response.status !== 423) { // Error 423 is that the competition close and is managed globally
                    this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
                }
            }

            this.$store.dispatch('statistics/setLikeLoading', false);
        },
        shuffleArray() {
            this.arrayHelper.shuffle(this.items);
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].random = i; // setting the random order
                // this.items[i].body = this.items[i].id + " " + i; // for test
            }
        },
        initialize($data) {
            // console.log(items)
            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0

            // this.competitionEnded = !$data.isOpenCompetition;
            const recognitions = $data.data;
            const usersTemp = $data.included;

            const usersMap = {};
            usersTemp.forEach(user => {
                usersMap[user.id] = user;
            });

            // const recognitionsMap = {};

            recognitions.map(recognition => {
                const userId = recognition.attributes.recognized_user_id;

                recognition.userData = usersMap[userId];

                /*
                 const typeRecognition = recognition.attributes.type_recognition;

                 const key = userId + '-' + typeRecognition;
                if (!recognitionsMap[key]) {
                    recognitionsMap[key] = [];
                }

                recognitionsMap[key].push(recognition); */

                return recognition;
            });

            // this.recongnitionsMap = recognitionsMap;
            /* this.items = Object.keys(recognitionsMap).map(userIdRecognitionType => {
                const values = userIdRecognitionType.split("-");

                return { key: userIdRecognitionType, userData: usersMap[values[0]], recognitionType: +values[1], recognitions: recognitionsMap[userIdRecognitionType] };
            }); */
            this.items = recognitions;
            this.usersMap = usersMap;
            this.users = usersTemp;
            //  this.shuffleArray();
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },
        editItem(item) {
            const itemEdit = {
                type: "purposesCloud",
                id: item.id,
                attributes: {
                    body: item.body,
                    order: item.order,
                    "user-id": item.user_id,
                    "created-at": item.created_at, // change the format to "2021-06-25T09:50:45+00:00",
                    "updated-at": item.updated_at // change the format to "2021-07-07T13:26:12+00:00"
                }
            };
            this.$router.push({ name: 'PurposeCreate', params: { item: JSON.stringify(itemEdit), isPurposeCloud: 'true' } });
        },
        createPurpose() {
            this.$router.push({ name: 'PurposeCreate' });
        },

        async deletePurposeCloudConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete("/purposesCloud" + urlAppend);

                if (result.status === 204) {
                    this.purposeCloud = null;
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
            }
            this.$store.dispatch('loading/end')
        },

        /**
       Show the coins componnents for purposes not mine and for purposes of my area
       * */
        canShowCoins(item) {
            if (item.user_id !== this.getUser.id) {
                // const purposeGroup = item.group_id;

                // true if at least 1 group in the item math with the purpose group
                // return this.getMyGroups.some(elem => elem == purposeGroup);

                return true;
            }

            return false;
        },
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                const aAtts = a.userData.attributes;
                const bAtts = b.userData.attributes;
                const aComp = aAtts.sociedad + ' ' + aAtts.name + ' ' + aAtts.lastname + ' ' + aAtts.type_recognition;
                const bComp = bAtts.sociedad + ' ' + bAtts.name + ' ' + bAtts.lastname + ' ' + bAtts.type_recognition;

                if (!isDesc[0]) {
                    return aComp < bComp ? -1 : 1
                    // return a[index] < b[index] ? -1 : 1
                } else {
                    return bComp < aComp ? -1 : 1
                }
            })
            return items
        }
    }
}
</script>

<style scoped>

</style>
