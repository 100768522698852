<template>
    <div>
        <span class="text-h6">{{ $t('recognitions.filterTypes') }}</span>
        <v-checkbox
            v-for="type in typeRecognition"
            :key="type.id"
            v-model="typesSelected"
            :value="type.id"
            :label="type.name"
            hide-details
            class="mt-1"
        />
    </div>
</template>

<script>

import typesRecognitions from '@/data/typesRecognitions'
import { mapGetters } from "vuex";

export default {
    name: "SelectRecognitionType",
    data: function () {
        return {
            typesSelected: []
        }
    },
    computed: {
        ...mapGetters("languages", ['getLang']),
        typeRecognition() {
            return typesRecognitions.map(type => ({ id: type.id, name: type[`name_${this.getLang}`] }));
        }
    },
    watch: {
        typesSelected: {
            handler(value) {
                const valueEmit = this.typesSelected.length === this.typeRecognition.length ? null : value;
                this.$emit('update:filter', valueEmit);
            },
            deep: true
        }
    },
    mounted() {
        this.typesSelected = this.typeRecognition.map(type => type.id);
    }
}
</script>

<style scoped>

</style>
