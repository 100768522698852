<template>
    <div>
        <div v-if="loading"></div>
        <div v-else>
            <div
                class="title-post"
                style="min-height: 1.93rem;"
            >
                <span> {{ title }} </span>
            </div>

            <recognitionHelp />
            <voteRecognitionsList />
        </div>
    </div>
</template>

<script>

import voteRecognitionsList from "@/components/votesRecognitions/voteRecognitionsList";
import postMixin from '@/mixins/postMixin'
import recognitionHelp from "@/components/votesRecognitions/recognitionHelp";

export default {
    name: 'VoteRecognitionsView',
    components: { voteRecognitionsList, recognitionHelp },
    mixins: [postMixin],
    data: function () {
        return {
            loading: true
        }
    },
    computed: {
        title() {
            const attributes = this.post.attributes
            return attributes.title2 ?? attributes.title
        }
    },
    async created() {
        this.loading =  true;
        if(this.$store.getters['configGeneral/isVotationVisible'] !== true){
            return this.$router.push("/");
        }
        this.loading =  false;

        /* const isOpenVotation = await this.$store.dispatch('configGeneral/isOpenVotation');
        if (!isOpenVotation) this.$router.push('/'); */
    }
}
</script>

<style scoped>

</style>
