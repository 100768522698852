<template>
    <div
        class="mx-4 mt-2"
        style="display: flex; justify-content: center"
    >
        <v-dialog
            v-model="dialog"
            width="800px"
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="my-3 text-gray-600"
                    style="display: flex; "
                >
                    <span
                        style="cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                    > {{ $t('votesRecognitions.help.howVoteTitle') }}</span>

                    <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon dark>
                            mdi-information-variant
                        </v-icon>
                    </v-btn>
                </div>

                <!--                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    Open Dialog
                </v-btn>-->
            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t('votesRecognitions.help.howVoteTitle') }}</span>
                </v-card-title>
                <v-card-text>
                    <div v-html="$t('votesRecognitions.help.howVoteBody')" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />

                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false"
                    >
                        {{ $t('common.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "RecognitionHelp",
    data() {
        return {
            dialog: false
        }
    },
    async mounted() {
        // this.dialog = !await this.$store.dispatch('cache/getAndUpdatePersistentInfo', { key: 'vote-recognition-help', value: true });
    }
}
</script>

<style scoped>

</style>
